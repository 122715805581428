import api from '@/api'
// initial state
const state = {
  activeItem: {
    id: ''
  },
  dataList: []
}

// getters
const getters = {}

// actions
const actions = {
  setActiveItem ({ commit }, item) {
    commit('setActiveItem', item)
  },
  setActiveId ({ commit }, id) {
    commit('setActiveId', id)
  },
  getDataList ({ commit }) {
    api.dataRegion.listData().then(res => {
      if (res && res.length) {
        commit('getDataList', res)
      }
    })
  }
}

// mutations
const mutations = {
  setActiveItem (state, item) {
    state.activeItem = item
  },
  setActiveId (state, id) {
    state.activeItem = state.dataList.find(item => item.id === id) || {}
  },
  getDataList (state, dataList) {
    state.dataList = dataList
    if (!state.activeItem.id) {
      state.activeItem = dataList[4] || dataList[0]
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
