import axios from '../utils/request'
export default {
  getKeywordList: params => {
    return axios({
      url: '/api/keyword/list',
      method: 'get',
      params: params
    })
  }
}
