import axios, { FormDataContentType } from '../utils/request'
import qs from 'qs'

export default {
  getWechatSignature: params => {
    return axios({
      url: '/api/wx/jsapi/signature',
      method: 'get',
      params
    })
  },
  base64Upload (base64) {
    return axios({
      url: '/api/base64-upload',
      method: 'post',
      headers: FormDataContentType,
      data: qs.stringify({ base64 })
    })
  }
}
