import axios from '../utils/request'

export default {
  getDashboardList (params) {
    return axios({
      url: '/api/dashboard/search',
      method: 'get',
      params
    })
  },
  getDashboardById (id) {
    return axios({
      url: `/api/dashboard/${id}`,
      method: 'get'
    })
  }
}
