import Vue from 'vue'
import axios from 'axios'
// import store from '../store'
import { Toast } from 'vant'
import eventBus from './eventBus'

const service = axios.create({
  // baseURL: '/api',
  // timeout: 300000
  // transformRequest: [function (data) {
  //   const d = qs.stringify(data)
  //   return d;
  // }]
})

const err = error => {
  if (error.response) {
    const data = error.response.data
    // const token = Vue.ls.get('access_token');
    if (error.response.status === 403) {
      Toast.fail('禁止访问: ' + data.message)
    } else if (error.response.status === 401) {
      Toast.fail('请登录')
      eventBus.$emit('goLogin')
    } else {
      Toast.fail(data.message || data.error)
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const accessToken = Vue.ls.get('accessToken')
  if (accessToken) {
    config.headers.Authorization = accessToken
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use(response => {
  return response.config.responseType === 'blob' ? response : response.data
}, err)

export default service

export const FormDataContentType = {
  'Content-Type': 'application/x-www-form-urlencoded'
}
