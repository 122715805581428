
const INPUT_STATUS_OPTION = {
  IDLE: 0,
  TYPING: 1
}
if (Object.freeze) {
  Object.freeze(INPUT_STATUS_OPTION)
}
// initial state
const state = {
  speechModalDisplay: false,
  // 2019年各地市应收电费年份
  // 2019年的应收电费和实收电费按月份
  speechRecognizedKeyword: '',
  inputKeyword: '',

  // 数据对标分类
  benchmarkingCategory: [],
  benchmarkingDataset: {},
  // 状态
  INPUT_STATUS_OPTION,
  inputStatus: 0,
  // 搜索历史，记录显示位置
  searchHistories: {},
  historyLocations: {},
  // 历史列表状态
  selectHistories: false,
  speechControl: false
}

// getters
const getters = {}

// actions
const actions = {
  showSpeechModal ({ commit }) {
    commit('setSpeechModalDisplay', true)
  },
  hideSpeechModal ({ commit }) {
    commit('setSpeechModalDisplay', false)
  },
  setSpeechRecognizedKeyword ({ commit }, keyword) {
    commit('setSpeechRecognizedKeyword', keyword)
  },
  setBenchmarkingCategory ({ commit }, data) {
    commit('setBenchmarkingCategory', data)
  },
  setBenchmarkingDataset ({ commit }, data) {
    commit('setBenchmarkingDataset', data)
  },
  setInputKeyword ({ commit }, keyword) {
    commit('setInputKeyword', keyword)
  },
  addSearchHistory ({ commit }, history) {
    commit('addSearchHistory', history)
  },
  removeSearchHistory ({ commit }, dataRegionId) {
    commit('removeSearchHistory', dataRegionId)
  },
  setSearchHistoryChartType ({ commit }, data) {
    commit('setSearchHistoryChartType', data)
  },
  setHistoryLocation ({ commit }, data) {
    commit('setHistoryLocation', data)
  },
  setSelectHistories ({ commit }, status) {
    commit('setSelectHistories', status)
  },
  setSpeechControl ({ commit }, bool) {
    commit('setSpeechControl', bool)
  }
}

// mutations
const mutations = {
  setSpeechModalDisplay (state, status) {
    state.speechModalDisplay = status
  },
  setSpeechRecognizedKeyword (state, keyword) {
    state.speechRecognizedKeyword = keyword
  },
  setBenchmarkingCategory (state, data) {
    state.benchmarkingCategory = data
  },
  setBenchmarkingDataset (state, data) {
    state.benchmarkingDataset = data
  },
  setInputStatus (state, status) {
    state.inputStatus = status
  },
  setInputKeyword (state, keyword) {
    state.inputKeyword = keyword
  },
  addSearchHistory (state, history) {
    const dataRegionId = history['dataRegionId']
    let dataList = state.searchHistories[dataRegionId]
    let location = state.historyLocations[dataRegionId]
    if (location === undefined) location = 0
    if (dataList === undefined) dataList = []
    const index = dataList.findIndex(item => item.searchWords === history.searchWords) // 如果有相同的，替换到最新
    if (index !== -1) dataList.splice(index, 1)
    dataList.push(JSON.parse(JSON.stringify(history)))
    location = dataList.length - 1
    state.searchHistories[dataRegionId] = dataList
    state.historyLocations[dataRegionId] = location
  },
  removeSearchHistory (state, dataRegionId) {
    delete state.searchHistories[dataRegionId]
    delete state.historyLocations[dataRegionId]
  },
  setSearchHistoryChartType (state, data) {
    const dataRegionId = data['dataRegionId']
    const dataList = state.searchHistories[dataRegionId]
    let location = state.historyLocations[dataRegionId]
    if (dataList === undefined) return
    if (location === undefined) location = 0
    dataList[location]['chartType'] = data.chartType
    state.searchHistories[dataRegionId] = dataList
  },
  setHistoryLocation (state, data) {
    const dataRegionId = data['dataRegionId']
    state.historyLocations[dataRegionId] = data['historyLocation']
  },
  setSelectHistories (state, status) {
    state.selectHistories = status
  },
  setSpeechControl (state, bool) {
    state.speechControl = bool
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
