import Vue from 'vue'
import Vuex from 'vuex'
import global from './modules/global'
import speech from './modules/speech'
import user from './modules/user'
import dataRegion from './modules/dataRegion'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    global,
    speech,
    user,
    dataRegion
  }
})
