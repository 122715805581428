import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/home',
    name: 'Home',
    redirect: '/index',
    component: () => import('../views/Home'),
    meta: {
      title: '主页'
    },
    children: [
      // {
      //   path: '/index',
      //   name: 'Text',
      //   component: () => import('../views/text'),
      //   meta: { title: '测试' }
      // },
      {
        path: '/index',
        name: 'HomePage',
        component: () => import('../views/homepage/Index'),
        meta: { title: '主页' }
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        redirect: '/dashboard/list',
        component: () => import('@/layouts/BlankLayout'),
        meta: { title: '仪表板' },
        children: [
          {
            path: '/dashboard/list',
            name: 'DashboardList',
            component: () => import('../views/dashboard/Index'),
            meta: { title: '仪表板列表' }
          },
          {
            path: '/dashboard/view/:id',
            name: 'DashboardPage',
            component: () => import('../views/dashboard/DashboardPage'),
            meta: {
              title: '仪表板查看'
            }
          }
        ]
      },
      {
        path: '/speech/',
        name: 'Speech',
        component: () => import('../views/speech/Index'),
        meta: { title: '搜索' }
      },
      {
        path: '/ai',
        name: 'Ai',
        component: () => import('../views/ai/Index'),
        meta: { title: 'Ai输入' }
      },
      {
        path: '/speechControl',
        name: 'SpeechControl',
        component: () => import('../views/speech/SpeechControl'),
        meta: { title: '语音主控' }
      },
      {
        path: '/message',
        name: 'Message',
        redirect: '/message/index',
        component: () => import('@/layouts/BlankLayout'),
        meta: { title: '消息查看' },
        children: [
          {
            path: '/message/index',
            name: 'MessageIndex',
            component: () => import('../views/message/Index'),
            meta: { title: '消息查看' }
          },
          {
            path: '/message/detail',
            name: 'MessageDetail',
            component: () => import('../views/message/MessageDetail'),
            meta: { title: '消息详情' }
          }
        ]
      },
      {
        path: '/setting',
        name: 'Setting',
        redirect: '/user',
        component: () => import('@/layouts/TransitionLayout'),
        meta: { title: '设置' },
        children: [
          {
            path: '/user',
            name: 'User',
            components: { User: () => import('../views/user/Index') },
            meta: { title: '会员中心' }
          },
          {
            path: '/user/basic-setting',
            name: 'BasicSetting',
            components: { BasicSetting: () => import('../views/user/setting/BasicSetting') },
            meta: { title: '基础设置' }
          },
          {
            path: '/user/security',
            redirect: '/user/security/security-setting',
            components: { Security: () => import('@/layouts/TransitionLayout') },
            meta: { title: '安全设置' },
            children: [
              {
                path: '/user/security/security-setting',
                name: 'SecuritySetting',
                components: {
                  SecuritySetting: () => import('../views/user/setting/SecuritySetting')
                },
                meta: { title: '安全设置' }
              },
              {
                path: '/user/security/change-password',
                name: 'ChangePassword',
                components: {
                  ChangePassword: () => import('../views/user/setting/ChangePassword')
                },
                meta: { title: '修改密码' }
              },
              {
                path: '/user/security/change-secret-phone',
                name: 'ChangeSecretPhone',
                components: { ChangeSecretPhone: () => import('../views/user/setting/ChangeSecretPhone') },
                meta: { title: '修改密保手机' }
              },
              {
                path: '/user/security/change-email',
                name: 'ChangeEmail',
                components: { ChangeEmail: () => import('../views/user/setting/ChangeEmail') },
                meta: { title: '修改备用邮箱' }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: () => import('../views/user/Login'),
    meta: {
      title: '用户登录'
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/public-chart/:keyword',
    name: 'PublicChart',
    component: () => import('../views/PublicChart'),
    meta: {
      title: '公开图表'
    }
  }
]

// routes.forEach(route => {
//   route.path = route.path || '/' + (route.name || '');
// });

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }
  next()
})

router.afterEach((to) => {
  const path = to.fullPath.slice(1) // 去除'/'
  if (!sessionStorage.getItem('initLink')) {
    // 解决ios微信下，分享签名不成功的问题,将第一次的进入的url缓存起来。
    sessionStorage.setItem('initLink', document.URL)
  }
  let url
  if (window.__wxjs_is_wkwebview) {
    // ios
    url = sessionStorage.getItem('initLink')
  } else {
    // 安卓 process.env.BASE_URL 自己定义各个环境下域名变量
    url = location.origin + '/' + path
  }
  store.commit('global/setInitLink', url)
})

export default router
