import axios from '../utils/request'

export default {
  getDatasetEnums () {
    return axios.get('/api/dataset/enums', {
      dataType: 'json',
      custom: { auth: true }
    })
  },
  getDataPreview (dataSourceId, dataSourceTable, page, size) {
    const _page = page || 0
    const _size = size || 100
    const _url = `/api/data-source/${dataSourceId}/tables/${dataSourceTable}/preview?page=${_page}&size=${_size}`
    return axios.get(_url, {
      dataType: 'json',
      custom: { auth: true }
    })
  }
}
