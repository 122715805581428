import axios from '../utils/request'

export default {
  getDatasetEnums () {
    return axios.get('/api/dataset/enums', {
      dataType: 'json',
      custom: { auth: true }
    })
  },
  getDataPreview () {

  },
  getFieldValues (dataFieldId) {
    return axios({
      url: `/api/datasets/data-fields/${dataFieldId}`,
      method: 'GET'
    })
  }
}
