import global from './global'
import user from './user'
import speech from './speech'
import benchmarking from './benchmarking'
import dataset from './dataset'
import dataSource from './dataSource'
import dataRegion from './dataRegion'
import security from './security'
import earlyWarning from './earlyWarning'
import dashboard from './dashboard'
import keyword from './keyword'
import privacy from './privacy'
import searchDocument from './searchDocument'
import ai from './ai'
export default {
  global,
  user,
  speech,
  benchmarking,
  dataset,
  dataSource,
  dataRegion,
  security,
  earlyWarning,
  dashboard,
  keyword,
  privacy,
  searchDocument,
  ai
}
