import axios from '../utils/request'

export default {
  changePassword (params) {
    return axios({
      url: '/api/users/change-password',
      method: 'PUT',
      params
    })
  },
  // sendCaptcha (params) {
  //   return axios({
  //     url: '/api/binding/sms-captcha',
  //     method: 'POST',
  //     params
  //   })
  // },
  sendCaptcha (params) {
    return axios({
      url: '/api/auth/sms/captcha',
      method: 'POST',
      params
    })
  },
  bindMobile (params) {
    return axios({
      url: '/api/binding/bind-mobile',
      method: 'POST',
      params
    })
  },
  sendMail (params) {
    return axios({
      url: '/api/binding/mail-captcha',
      method: 'POST',
      params
    })
  },
  getImgCaptcha () {
    return axios({
      url: '/api/auth/image/captcha',
      method: 'GET'
    })
  }
}
