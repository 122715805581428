import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import Storage from 'vue-ls'
import eventBus from './utils/eventBus'
import VueScroller from 'vue-scroller'
import WeChat from 'vue-wechat'
import './utils/filter' // global filter

import {
  Toast,
  ActionSheet,
  Button,
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  Col,
  Collapse,
  CollapseItem,
  DatetimePicker,
  Dialog,
  DropdownItem,
  DropdownMenu,
  Empty,
  Field,
  Form,
  Grid,
  GridItem,
  Icon,
  Image,
  Lazyload,
  List,
  Loading,
  NavBar,
  Notify,
  NumberKeyboard,
  Overlay,
  Pagination,
  Panel,
  Picker,
  Popover,
  Popup,
  Radio,
  RadioGroup,
  Row,
  Search,
  ShareSheet,
  Stepper,
  Swipe,
  SwipeItem,
  Tab,
  Tabbar,
  TabbarItem,
  Tabs,
  Tag,
  Uploader,
  SwipeCell,
  Calendar,
  TreeSelect,
  Switch,
  Sticky,
  Badge,
  PullRefresh
} from 'vant'

import Viser from 'viser-vue'
import LongPress from './directives/longpress'

import 'vant/lib/icon/local.css'
import './theme/fonts/iconfont.css'
import './theme/style.less'

Vue.use(VueScroller)
Vue.use(Button)
Vue.use(Form)
Vue.use(Field)
Vue.use(Search)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Icon)
Vue.use(Image)
Vue.use(Lazyload)
Vue.use(List)
Vue.use(NumberKeyboard)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Loading)
Vue.use(ActionSheet)
Vue.use(Stepper)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(DatetimePicker)
Vue.use(NavBar)
Vue.use(Uploader)
Vue.use(Col)
Vue.use(Row)
Vue.use(Tag)
Vue.use(Panel)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Empty)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Popover)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Viser)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(ShareSheet)
Vue.use(Overlay)
Vue.use(SwipeCell)
Vue.use(Calendar)
Vue.use(TreeSelect)
Vue.use(Switch)
Vue.use(Sticky)
Vue.use(Badge)
Vue.use(PullRefresh)

Vue.use(WeChat)
Vue.use(Storage, {
  namespace: 'xbi__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local' // storage name session, local, memory
})

LongPress.install(Vue)

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$toast = Toast
Vue.prototype.$notify = Notify
Vue.prototype.$eventBus = eventBus

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
