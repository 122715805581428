import axios from '../utils/request'
import axiosOrigin from 'axios'
import qs from 'qs'
import { FormDataContentType } from '@/utils/request'

const CancelToken = axiosOrigin.CancelToken
let hintsSource = null

export default {
  parseMixKeyword (data) {
    return axios({
      url: '/api/report/mix-keywords',
      method: 'POST',
      data
    })
  },
  getReportKeyword: params => {
    return axios({
      url: '/api/report/by-keyword',
      method: 'get',
      params
    })
  },
  getReportData (data, ignoreImplicit, refreshHistory) {
    let url = '/api/report/data'
    if (ignoreImplicit && refreshHistory) { // 暂时不会用到
      url += '?ignoreImplicit=' + ignoreImplicit + '&refreshHistory=' + refreshHistory
    } else if (refreshHistory) {
      url += '?refreshHistory=' + refreshHistory
    } else if (ignoreImplicit) {
      url += '?ignoreImplicit=' + ignoreImplicit
    }
    return axios({
      url: url,
      method: 'POST',
      data
    })
  },
  getDiyModelData: data => {
    return axios({
      url: '/api/diy-models',
      method: 'POST',
      data
    })
  },
  getReportDataBatch: data => {
    return axios({
      url: '/api/report/data/batch',
      method: 'POST',
      data
    })
  },
  getKeywordHistoryList (params) {
    return axios({
      url: '/api/keyword/recent',
      params,
      method: 'get'
    })
  },
  getKeywordSampleGrouped () {
    return axios({
      url: '/api/keyword/samples/group',
      method: 'get'
    })
  },
  getSuggestsData (params) { // 查询猜你搜索
    return axios({
      url: '/api/keyword/suggests',
      params,
      method: 'get'
    })
  },
  getKeywordRecentList (params) { // 查询最近搜索
    return axios({
      url: '/api/keyword/recent',
      params,
      method: 'get'
    })
  },
  keywordHints: params => {
    if (hintsSource) {
      hintsSource.cancel('RequestRepeat')
    }
    hintsSource = CancelToken.source()
    const data = qs.stringify(params)
    return axios({
      url: '/api/keyword/hints',
      method: 'POST',
      headers: FormDataContentType,
      data,
      cancelToken: hintsSource.token
    })
  },
  getCollectionsList (params) { // 查询收藏列表
    return axios({
      url: '/api/keyword/collections/search',
      params,
      method: 'get'
    })
  },
  searchTrackList (type, params) {
    const urls = {
      collections: '/api/keyword/collections/search',
      recent: '/api/keyword/recent',
      suggests: '/api/keyword/suggests',
      headlines: '/api/keyword/headlines/search'
    }
    return axios({
      url: urls[type],
      params,
      method: 'get'
    })
  },
  deleteSearchTrack (type, id) {
    const urls = {
      recent: `/api/keyword/search-histories/${id}`,
      suggests: `/api/dataset/dataset-suggests/${id}`,
      collections: `/api/keyword/collections/${id}/uncollect`,
      headlines: `/api/keyword/headlines/${id}/uncollect`
    }
    const method = ['recent', 'suggests'].includes(type) ? 'DELETE' : 'POST'
    return axios({
      url: urls[type],
      method
    })
  },
  collectKeywords (params) { // 收藏
    return axios({
      url: '/api/keyword/collections/collect',
      headers: FormDataContentType,
      data: qs.stringify(params),
      method: 'post'
    })
  },
  cancelCollectKeywords (params) { // 取消收藏
    return axios({
      url: '/api/keyword/collections/uncollect',
      headers: FormDataContentType,
      data: qs.stringify(params),
      method: 'post'
    })
  },
  postFeedback (data) {
    return axios({
      url: '/api/feedbacks',
      data,
      method: 'POST'
    })
  },
  updateHistoryThumb (id, params) { // 更新搜索历史略缩图
    return axios({
      url: `/api/keyword/search-histories/${id}/thumbnail`,
      headers: FormDataContentType,
      data: qs.stringify(params),
      method: 'post'
    })
  },
  getHeadlinesList (params) { // 查询首页显示列表
    return axios({
      url: '/api/keyword/headlines/search',
      params,
      method: 'get'
    })
  },
  collectHeadlines (params) { // 加入首页
    return axios({
      url: '/api/keyword/headlines/collect',
      headers: FormDataContentType,
      data: qs.stringify(params),
      method: 'post'
    })
  },
  cancelCollectHeadlines (params) { // 取消加入首页
    return axios({
      url: '/api/keyword/headlines/uncollect',
      headers: FormDataContentType,
      data: qs.stringify(params),
      method: 'post'
    })
  },
  searchHistories (id) { // 搜索历史记录数据
    return axios({
      url: `/api/keyword/search-histories/${id}`,
      method: 'get'
    })
  },
  searchTrackExists (type, params) { // 查询是否收藏或加入首页
    return axios({
      url: `/api/keyword/${type}/exists`,
      params,
      method: 'get'
    })
  },
  getMapInfo (params) {
    return axios({
      url: '/api/geo-info',
      method: 'GET',
      params
    })
  },
  getKeywords (id, keywordCategory) {
    const params = {
      keywordCategory: keywordCategory,
      relationEntityId: id
    }
    return axios({
      url: '/api/keyword/list',
      method: 'get',
      params
    })
  },
  getRelationshipDiagram (data) {
    return axios({
      url: '/api/graph/hot-words',
      method: 'POST',
      data
    })
  },
  getRecommends (data) {
    return axios({
      url: '/api/graph/recommends',
      method: 'POST',
      data
    })
  },
  getAvailableLangs () {
    return axios({
      url: '/api/speech/get_available_langs',
      method: 'GET'
    })
  }
}
