import axois, { FormDataContentType } from '../utils/request'

import qs from 'qs'

export default {
  loginPassword (params) {
    return axois({
      url: '/api/auth/login',
      method: 'post',
      data: qs.stringify(params),
      headers: FormDataContentType
    })
  },
  loginMobile (params) {
    return axois({
      url: '/api/auth/sms/login',
      method: 'POST',
      data: qs.stringify(params),
      headers: FormDataContentType
    })
  },
  logout () {
    return axois({
      url: '/api/auth/logout',
      method: 'post'
    })
  },
  // 获取用户信息
  getUserProfile () {
    return axois({
      url: '/api/users/profile',
      method: 'get'
    })
  },
  getSettings() {
    return axois({
      url: '/api/users/settings',
      method: 'get'
    })
  },
  saveSettings(data) {
    return axois({
      url: '/api/users/settings',
      method: 'POST',
      data
    })
  },
  // 修改密码
  changePassword (oldPassword, newPassword) {
    return axois({
      url: '/api/users/change-password',
      method: 'post',
      data: {
        oldPassword,
        newPassword
      }
    })
  },
  uploadAvatar (avatar) {
    return axois({
      url: '/api/users/upload-avatar',
      method: 'post',
      data: { avatar }
    })
  },
  sendMail (mail) {
    return axois({
      url: '/api/binding/mail-captcha',
      method: 'post',
      data: { mail }
    })
  },
  bindMobile (mobile, captcha) {
    return axois({
      url: '/api/binding/mail-mobile',
      method: 'post',
      data: {
        mobile,
        captcha
      }
    })
  },
  sendCaptcha (mobile) {
    return axois({
      url: '/api/binding/sms-captcha',
      method: 'post',
      data: { mobile }
    })
  }
}
