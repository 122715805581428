import axios from '../utils/request'

export default {
  listData () {
    return axios.get('/api/data-regions', {
      dataType: 'json',
      custom: { auth: true }
    })
  }
}
