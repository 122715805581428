import axios from '../utils/request'

export default {
  getUsersList: () => {
    return axios({
      url: `/api/security/users/search`,
      method: 'get'
    })
  },
  getRolesList () {
    return axios({
      url: '/api/security/roles',
      method: 'GET'
    })
  },
  scheduleEnums: () => {
    return axios({
      url: '/api/schedule/enums',
      method: 'GET'
    })
  },
  getNoticeList: params => {
    return axios({
      url: '/api/system/notices',
      method: 'GET',
      params
    })
  },
  deleteNotice: id => {
    return axios({
      url: '/api/system/notices/' + id,
      method: 'DELETE'
    })
  },
  markNoticeRead: (params, id) => {
    return axios({
      url: `/api/system/notices/${id}/read`,
      method: 'Put',
      params
    })
  },
  markNoticeReadAll: (params) => {
    return axios({
      url: `/api/system/notices/read-all`,
      method: 'POST',
      params
    })
  },
  noticeResults: detailUrl => {
    let url = '/api/schedule/notice-results'
    if (detailUrl) {
      url = '/api' + detailUrl
    }
    return axios({
      url: url,
      method: 'GET'
    })
  },
  setScheduleTask: params => {
    return axios({
      url: '/api/schedule/tasks',
      method: 'POST',
      data: params
    })
  },
  getTaskList: params => {
    return axios({
      url: '/api/schedule/tasks',
      method: 'GET',
      params
    })
  },
  deleteTask: id => {
    return axios({
      url: '/api/schedule/tasks/' + id,
      method: 'DELETE'
    })
  },
  editTask: (params) => {
    return axios({
      url: '/api/schedule/tasks/' + params.id,
      method: 'PUT',
      data: params
    })
  },
  getUnreadNum: () => {
    return axios({
      url: '/api/system/notices/unread',
      method: 'GET'
    })
  }
}
