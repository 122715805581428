// initial state
const state = {
  accessToken: '',
  profile: {
    avatar: ''
  },
  settings: {
    defaultPageType: '系统主页',
    extraParam: '',
    dataRegionId: ''
  },
  loginPageActive: 0
}

// getters
const getters = {}

// actions
const actions = {
  setAccessToken ({ commit }, token) {
    commit('setAccessToken', token)
  },
  setProfile ({ commit }, profile) {
    commit('setProfile', profile)
  },
  setSettings ({ commit }, settings) {
    commit('setSettings', settings)
  },
  logout ({ commit }) {
    commit('setAccessToken', '')
  },
  changeLoginPageActive ({ commit }) {
    commit('changeLoginPageActive', '')
  }
}

// mutations
const mutations = {
  setAccessToken (state, token) {
    state.accessToken = token
  },
  setProfile (state, profile) {
    state.profile = profile
  },
  setSettings (state, settings) {
    state.settings = settings || {}
  },
  changeLoginPageActive (state, data) {
    state.loginPageActive = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
