import axios from '@/utils/request'

// const ES_OPEN_URL = process.env.VUE_APP_API_STANDBY_URL // 在线测试服务薇地址（用于处理本地环境对标和ES搜索）

export default {
  queryHitPage: params => {
    return axios({
      url: '/api/es/query_hit_page',
      method: 'get',
      params
    })
  }
}
