import axios from '../utils/request'

export default {
  liveAddress: device => {
    return axios({
      url: '/api/yinshi/live-address?device=' + device,
      method: 'GET'
    })
  }
}
