import axios from '../utils/request'

export default {
  // 获取指标库
  getBenchmarkingCategory: () => {
    return axios({
      url: `/api/open/data-source/31197499776167936/tables/同业对标指标库/preview`,
      method: 'GET'
    })
  },
  getBenchmarkingDataset: () => {
    return axios({
      url: `/api/open/datasets/60385355566665728`,
      method: 'GET'
    })
  },
  getBenchmarkingDataBatch: data => {
    return axios({
      url: '/api/open/report/data/batch',
      method: 'POST',
      data
    })
  },
  getKeywordMatch: keyword => {
    return axios({
      url: '/api/open/report/by-keyword',
      method: 'get',
      params: {
        keyword,
        printDataRequest: true
      }
    })
  },
  getReportData: data => {
    return axios({
      url: '/api/open/report/data',
      method: 'POST',
      data
    })
  }
}
