<template>
  <div id="app">
    <!--https://cn.vuejs.org/v2/guide/transitions.html-->
    <transition name="slide-fade">
      <router-view/>
    </transition>
  </div>
</template>
<script>

export default {
  name: 'App',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
    initParams: async function () {
      const href = window.location.href
      const hrefArr = href.split('/')
      const path = hrefArr[hrefArr.length - 2].split('?')[0] // 获取链接最后/之后的内容
      if (path === 'public-chart') { // 此时打开公众号图表页面
        const initUsered = await this.initUser()
        if (initUsered) {
          // 加载数据域
          this.$store.dispatch('dataRegion/setActiveItem', {})
          this.$store.dispatch('dataRegion/getDataList')
        }
      } else {
        // 加载数据域
        this.$store.dispatch('dataRegion/setActiveItem', {})
        this.$store.dispatch('dataRegion/getDataList')
      }
    },
    initUser () {
      return new Promise((resolve, reject) => {
        this.$api.user.loginPassword({ // 免登录，先暂时用固定账号登录，因为获取数据域需要登录
          username: 'admin',
          password: 'Huiying2022'
        }).then(res => {
          const { access_token: accessToken, expires_in: expiresIn } = res
          this.$ls.set('accessToken', accessToken, (expiresIn - 1800) * 1000 + new Date().getTime())
          this.$store.commit('user/setAccessToken', accessToken)
          resolve(true)
        }).catch(() => {
          this.$toast('页面加载失败，请稍后再试')
        })
      })
    },
    initWeChat () {
      this.$api.global.getWechatSignature({
        url: document.URL
      }).then(res => {
        const { appId, timestamp, nonceStr, signature } = res
        this.$wechat.config({
          appId,
          timestamp,
          nonceStr,
          signature,
          jsApiList: [
            'checkJsApi',
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'startRecord',
            'stopRecord',
            'onVoiceRecordEnd',
            'translateVoice'
          ]
        })
        this.$wechat.ready(res => {
          // 微信SDK准备就绪后执行的回调。
          console.log('wx ready==========')
          this.$store.dispatch('global/setWxReady', true)
        })
      })
    }
  },
  mounted () {
    this.$eventBus.$on('goLogin', () => {
      this.$router.replace('/login')
    })
    this.$eventBus.$on('loginSuccess', this.initParams)
    this.initParams()
    if (navigator.userAgent.toLowerCase().match(/micromessenger/)) {
      this.$store.dispatch('global/setInWx', true)
      this.initWeChat()
    } else if (navigator.userAgent.toLowerCase().match(/qqbrowser/)) {
      this.$store.dispatch('global/setInXbiApp', true)
      // window['XbiApp']['webAppLoaded']()
    }
  }
}
</script>
<style>
  body {
    font-size: 16px;
    background-color: #f8f8f8;
    -webkit-font-smoothing: antialiased;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;

    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    touch-callout:none;
  }
  * {
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    touch-callout:none;
  }
  input {
    -webkit-user-select:auto;
    -khtml-user-select:auto;
    -moz-user-select:auto;
    -ms-user-select:auto;
    user-select:auto;
  }

</style>
