// initial state
const state = {
  // favorite
  // speech
  currentMainTabPage: 'speech',
  systemInfo: {
    pixelRatio: 1,
    windowWidth: 414,
    windowHeight: 672
  },
  initLink: '',
  // 当输入内容时，需要隐藏底部导航条
  hideTabBar: false,
  wxReady: false,
  inWx: false,
  inXbiApp: false,
  statusBarHeight: 30,
  deviceType: (navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Linux') > -1) ? 'android' : navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ? 'ios' : '', // 当前程序使用的设备，如：安卓app，微信小程序等，暂默认为安卓app
  iosBottomHeight: navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ? 30 : 0, // ios端底部横线状态栏高度
  moreHeight: navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ? 60 : 30 // 状态栏和ios端底部状态栏高度之和
}

// getters
const getters = {}

// actions
const actions = {
  changeCurrentMainTabPage ({ commit }, pageName) {
    commit('setCurrentMainTabPage', pageName)
  },
  setHideTabBar ({ commit }, hideTabBar) {
    commit('setHideTabBar', hideTabBar)
  },
  setInWx ({ commit }, status) {
    commit('setInWx', status)
  },
  setInXbiApp ({ commit }, status) {
    commit('setInXbiApp', status)
  },
  setWxReady ({ commit }, status) {
    commit('setWxReady', status)
  },
  setStatusBarHeight ({ commit }, status) {
    commit('setStatusBarHeight', status)
  }
}

// mutations
const mutations = {
  setCurrentMainTabPage (state, pageName) {
    state.currentMainTabPage = pageName
  },
  setSystemInfo (state, options) {
    state.systemInfo = options
  },
  setInitLink (state, initLink) {
    state.initLink = initLink
  },
  setHideTabBar (state, hideTabBar) {
    state.hideTabBar = hideTabBar
  },
  setInWx (state, status) {
    state.inWx = status
  },
  setInXbiApp (state, status) {
    state.inXbiApp = status
  },
  setWxReady (state, status) {
    state.wxReady = status
  },
  setStatusBarHeight (state, status) {
    state.statusBarHeight = status
    state.moreHeight = state.statusBarHeight + state.iosBottomHeight
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
